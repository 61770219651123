import Link from "next/link"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "next-i18next"
import styled from "styled-components"
import useSelectorTyped from "../../../store/useSelectorTyped"
import { A7ACenteredRow } from "../../../utils/Container"
import { useMediaQuery } from "../../../utils/useMediaQuery"
import { GenericButton, SocialButton } from "../button"
import A7ALogo from "../svg/common/logo/A7ALogo"
import RightArrow from "../svg/common/RightArrow"
import Github from "../svg/common/social/Github"
import Instagram from "../svg/common/social/Instagram"
import Twitter from "../svg/common/social/Twitter"
import Cross from "../svg/navbar/Cross"
import HamburgerIcon from "../svg/navbar/HamburgerIcon"
import SizedBox from "../ui/SizedBox"
import A7APageNavigator from "./A7APageNavigator"
import { ThemeSlider } from "./ThemeSlider"
import { useRouter } from "next/router"

export const NavbarWrapper = styled.div<{
  isNavbarOpen: boolean
  isScrolled: boolean
}>`
  height: 85px;
  backdrop-filter: ${(props) => (!props.isScrolled ? "none" : "blur(10px)")};
  background: ${(props) =>
    !props.isScrolled ? "none" : props.theme.colors.backdropBlur};
  top: ${(props) => (props.isNavbarOpen || props.isScrolled ? "0" : "30px")};
  position: fixed;
  padding: ${(props) =>
    props.isNavbarOpen || props.isScrolled ? "0" : "0 16px"};
  width: 100%;
  z-index: 3;
  transition: 0.2s all;
  ${(props) => props.theme.mediaQuery.tablet} {
    top: ${(props) => (props.isScrolled ? "0" : "50px")};
  }
`

export const NavbarContainer = styled.div<{
  isNavbarOpen: boolean
  isScrolled: boolean
}>`
  max-width: calc(${(props) => props.theme.desktopWidth} + 30px);
  transition: 0.3s all;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;
  z-index: 3;
  padding: 16px 0px;
  height: 85px;

  backdrop-filter: ${(props) =>
    props.isScrolled || props.isNavbarOpen ? "blur(0px)" : "blur(10px)"};
  background: ${(props) =>
    props.isScrolled || props.isNavbarOpen
      ? "none"
      : props.theme.colors.backdropBlur};
  border-radius: ${(props) =>
    props.isScrolled ? "0" : props.theme.borderRadius};
  margin-top: ${(props) => (props.isNavbarOpen ? "28px" : 0)};
  padding: ${(props) => (props.isNavbarOpen ? "16px" : "16px 0")};
  ${(props) => props.theme.mediaQuery.tablet} {
    padding: 0px 15px;
  }
`

const NavbarTopView = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 15px;
  ${(props) => props.theme.mediaQuery.desktop} {
    margin: 0px;
  }
`

export const Navbarleft = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const NavbarMobileSwitch = styled.div`
  display: none;
  align-items: center;
  width: fit-content;
  height: fit-content;
  justify-content: flex-end;
  svg {
    cursor: pointer;
  }
  display: flex;
  ${(props) => props.theme.mediaQuery.tablet} {
    display: none;
  }
`

const NavbarRight = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: flex-end;
  grid-gap: 25px;
  @media only screen and (max-width: 767px) {
    display: none;
  }
  @media only screen and (max-width: 860px) {
    grid-gap: 12px;
  }
`

const NavbarLink = styled.div`
  font-weight: 500;
  font-family: "LEMON MILK";
  font-size: 16px;
  line-height: 48px;
  color: ${(props) => props.theme.colors.textColors[0]};
  text-align: center;
`

const NavbarExpandedViewWrapper = styled.div<{
  isNavbarOpen: boolean
  isDarkTheme: number
}>`
  background-image: url(${(props) =>
    props.isDarkTheme === 0
      ? "/images/MobileNavbarBackground.webp"
      : "/images/LightBackground.webp"});
  background-color: ${(props) => props.theme.background};
  min-height: calc(100vh + 40px);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s ease;
  overflow-y: auto !important;
  background-size: cover;
  z-index: 2;
  padding-bottom: 50px;
  opacity: ${(props) => (props.isNavbarOpen ? 1 : 0)};
  /* visibility: ${(props) => (props.isNavbarOpen ? "visible" : "hidden")}; */
  transform: ${(props) =>
    props.isNavbarOpen ? "translateY(-130px)" : "translateY(-100%)"};
  will-change: transform;
`

const NavbarExpandedView = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 394px;
  margin: ${(props) => props.theme.constrainedMargin};
  align-items: center;
  padding: 30px 0px;
  background: ${(props) => props.theme.colors.backdropBlur};
  background-blend-mode: multiply;
  backdrop-filter: blur(5px);
  gap: 30px;
  border-radius: 20px;
`

const NavbarExpandedViewFooter = styled(NavbarExpandedView)`
  min-height: auto;
  gap: 0px;
  height: 178px;
  /* height: 178px; */
`

const NavbarSocialButtonsContainer = styled(A7ACenteredRow)`
  width: 100%;
  height: auto;
  gap: 18px;
`

export interface NavbarProps {
  activeIndex: number
  isNavbarOpen?: boolean
}

const Navbar = ({ activeIndex }: NavbarProps) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false)
  const [activeIndexState, setActiveIndexState] = useState(activeIndex)
  const [isScrolled, setIsScrolled] = useState(false)
  const navContainerRef = useRef<any>()
  const isMobile = useMediaQuery("(max-width: 450px)")
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)
  const router = useRouter()
  const { t } = useTranslation("common")

  const navItems = [
    {
      name: t(`navbar.0.Labs`),
      link: "/?section=labs",
      scrollToTop: false,
    },
    {
      name: t(`navbar.1.Projects`),
      link: "/?section=projects",
      scrollToTop: false,
    },
    {
      name: t(`navbar.2.Tech-Stack`),
      link: "/?section=tech-stack",
      scrollToTop: false,
    },
    // {
    //   name: t(`navbar.6.Team`),
    //   link: "/team",
    //   scrollToTop: true,
    // },
    {
      name: t(`navbar.3.Reviews`),
      link: "/?section=reviews",
      scrollToTop: false,
    },
    // {
    //   name: t(`navbar.4.Articles`),
    //   link: "/articles",
    //   scrollToTop: true,
    // },
    process.env.IS_HIRING == "true"
      ? {
          name: t(`navbar.5.Career`),
          link: "/?section=career",
          scrollToTop: false,
        }
      : null,
  ]

  useEffect(() => {
    setActiveIndexState(activeIndex)
  }, [activeIndex])

  useEffect(() => {
    if (isNavbarOpen) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "auto"
    }
  }, [isNavbarOpen])

  useEffect(() => {
    let scrollEvent: any = document.addEventListener("scroll", (e) => {
      if (document.body.getBoundingClientRect().top < -200) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    })
    document.removeEventListener("scroll", scrollEvent)
  }, [])

  const handleLogoClick = () => {
    if (router.asPath == "/") {
      console.log("MMHMM")
      window.scroll({
        top: 0,
        left: 0,
      })
    }
  }

  return (
    <NavbarWrapper isNavbarOpen={isNavbarOpen} isScrolled={isScrolled}>
      <NavbarContainer
        ref={navContainerRef}
        isNavbarOpen={isNavbarOpen}
        isScrolled={isScrolled}
      >
        <NavbarTopView>
          <Navbarleft>
            <A7ACenteredRow>
              <Link href={"/"} aria-label="Home" onClick={handleLogoClick}>
                <A7ALogo
                  props={{
                    width: isMobile ? "54" : "66",
                    height: isMobile ? "44" : "50",
                  }}
                />
              </Link>
              <SizedBox width={16} height={0} />
              <ThemeSlider />
            </A7ACenteredRow>

            {!isMobile && <SizedBox height={0} width={30} />}
            <NavbarMobileSwitch>
              {!isNavbarOpen && (
                <HamburgerIcon
                  onClick={() => setIsNavbarOpen((e: boolean) => !e)}
                />
              )}
              {isNavbarOpen && (
                <Cross onClick={() => setIsNavbarOpen((e: boolean) => !e)} />
              )}
            </NavbarMobileSwitch>
          </Navbarleft>
          <NavbarRight>
            <A7APageNavigator
              items={navItems}
              ref={navContainerRef}
              activeIndexState={activeIndexState}
            />
            <Link scroll={false} href={"/?section=contact"}>
              <GenericButton
                text={t("contact-us")}
                height={46}
                width={140}
                isFullWidth={false}
                isDisabled={false}
                isProcessing={false}
                onClick={() => {}}
              />
            </Link>
          </NavbarRight>
        </NavbarTopView>
      </NavbarContainer>
      <NavbarExpandedViewWrapper
        isNavbarOpen={isNavbarOpen}
        isDarkTheme={theme}
      >
        <SizedBox height={150} width={0} />
        <NavbarExpandedView>
          {navItems
            .filter((item) => item !== null)
            .map((item, index) => (
              <Link
                key={index}
                scroll={false}
                href={item?.link || ""}
                onClick={() => setIsNavbarOpen(!isNavbarOpen)}
                aria-label={item?.name}
              >
                <NavbarLink>{item?.name}</NavbarLink>
              </Link>
            ))}
        </NavbarExpandedView>
        <SizedBox height={65} width={0} />
        <NavbarExpandedViewFooter>
          <Link legacyBehavior scroll={false} href={"/?section=contact"}>
            <GenericButton
              text={t("navbar.7.letsTalk")}
              height={50}
              width={isMobile ? 220 : 255}
              isFullWidth={false}
              isDisabled={false}
              isProcessing={false}
              onClick={() => setIsNavbarOpen(!isNavbarOpen)}
            >
              <SizedBox width={18} height={0} />
              <RightArrow />
            </GenericButton>
          </Link>
          <SizedBox height={30} width={0} />
          <NavbarSocialButtonsContainer>
            <a
              href="https://github.com/A7ALABS"
              target="_blank"
              rel="noreferrer"
            >
              <SocialButton onClick={() => {}} ariaLabel="Github">
                <Github />
              </SocialButton>
            </a>
            <a
              href="https://instagram.com/A7ALABS"
              target="_blank"
              rel="noreferrer"
            >
              <SocialButton onClick={() => {}} ariaLabel="Instagram">
                <Instagram />
              </SocialButton>
            </a>
            <a
              href="https://twitter.com/A7ALABS"
              target="_blank"
              rel="noreferrer"
            >
              <SocialButton onClick={() => {}} ariaLabel="Twitter">
                <Twitter />
              </SocialButton>
            </a>
          </NavbarSocialButtonsContainer>
        </NavbarExpandedViewFooter>
      </NavbarExpandedViewWrapper>
    </NavbarWrapper>
  )
}

export default Navbar
